<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list' }"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <user-view-user-info-card :user-data="userData" />
      <user-view-user-permissions-card :user-data="userData" />
    </template>
  </div>
</template>

<script>
import { BAlert, BLink } from 'bootstrap-vue'
import UserViewUserInfoCard from './users-view/UserViewUserInfoCard.vue'
import UserViewUserPermissionsCard from './users-view/UserViewUserPermissionsCard.vue'

export default {
  components: {
    BAlert,
    BLink,

    UserViewUserInfoCard,
    UserViewUserPermissionsCard,
  },
  computed: {
    userData() {
      return this.$store.state.users.userData
    },
  },
  created() {
    this.$store.dispatch('users/fetchUserData', {
      uuid: this.$route.params.uuid,
    })
  },
}
</script>

<style>
</style>
